import React, { FC, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Field, Form } from "react-final-form";
import * as yup from "yup";
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SafeKaroUser } from "../../../../context/constant";
import { setIn } from "final-form";
import { IAddMenu, IEditMenu, IMenu } from "../../../../api/Menu/IMenuType";
import EditMenuService from "../../../../api/Menu/EditMenu/EditMenuService";
import AddMenuService from "../../../../api/Menu/AddMenu/AddMenuService";
import useGetMenu from "../../../../Hooks/Menu/useGetMenu";
import useGetRoles from "../../../../Hooks/Role/useGetRoles";
interface AddMenuFromProps {
  p?: IMenu;
}
const AddMenuFrom: FC<AddMenuFromProps> = ({ p }) => {
  const navigate = useNavigate();
  const [roles] = useGetRoles();
  let storedTheme: any = localStorage.getItem("user") as SafeKaroUser | null;
  let UserData = storedTheme ? JSON.parse(storedTheme) : storedTheme;
  const [menuData] = useGetMenu();
  const [selectedParentId, SetSelectedParentId] = useState<string | number>(
    p?.parentId || 0
  );
  const [selectedRoleId, SetSelectedRoleId] = useState<string>(p?.roleId || "");
  const callEditMenu = async (payload: IEditMenu) => {
    try {
      const res = await EditMenuService(payload);
      if (res.status === "success") {
        navigate(-1);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  const findByParentId = (parentId: string) => {
    return menuData?.find((ele) => ele._id === parentId);
  };
  const findRoleById = (roleId: string) => {
    return roles?.find((ele) => ele._id === roleId);
  };
  const callAddMenu = async (payload: IAddMenu) => {
    try {
      const res = await AddMenuService(payload);
      if (res.status) {
        navigate(-1);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  const validateFormValues = (schema: any) => async (values: any) => {
    if (typeof schema === "function") {
      schema = schema();
    }
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (err: any) {
      const errors = err.inner.reduce((formError: any, innerError: any) => {
        return setIn(formError, innerError.path, innerError.message);
      }, {});
      return errors;
    }
  };
  const validationSchema = yup.object().shape({
    displayName: yup
      .string()
      .required("Menu Name Name is required")
      .min(1, "Menu name must be at least 1 character")
      .max(100, "menu Name cannot exceed 100 characters"),
    pageURL: yup
      .string()
      .required("Page URL  is required")
      .min(1, "Page URL must be at least 1 character")
      .max(100, "Page URL cannot exceed 100 characters"),
    role: yup.object().required("Role is required"),
  });
  const validate = validateFormValues(validationSchema);
  const onSubmit = (values: any) => {
    const { displayName, pageURL, childOrder, parentOrder, cssClass, role } =
      values;
    if (p) {
      callEditMenu({
        id: p._id,
        displayName,
        pageURL,
        childOrder,
        parentOrder,
        cssClass,
        roleId: selectedRoleId,
        role: role.roleName,
      });
    } else {
      callAddMenu({
        displayName,
        pageURL,
        createdBy: UserData.name,
        isActive: true,
        parentId: selectedParentId.toString(),
        childOrder,
        parentOrder,
        cssClass,
        roleId: selectedRoleId,
        role: role.roleName,
      });
    }
  };
  const handleMenuChange = (menu: any) => {
    if (menu?._id) {
      SetSelectedParentId(menu._id);
    } else {
      SetSelectedParentId(0);
    }
  };
  const handleRoleChange = (role: any) => {
    if (role?._id) {
      SetSelectedRoleId(role._id);
    } else {
      SetSelectedRoleId("");
    }
  };
  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          displayName: p?.displayName,
          pageURL: p?.pageURL,
          childOrder: p?.childOrder,
          parentOrder: p?.parentOrder,
          parentId: findByParentId(selectedParentId.toString() ?? "0"),
          role: findRoleById(selectedRoleId),
          cssClass: p?.cssClass,
        }}
        validate={validate}
        render={({ handleSubmit, submitting, errors }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="role">
                  {({ input, meta }) => (
                    <div>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="role"
                          value={input.value !== undefined ? input.value : null}
                          options={roles}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option?.roleName || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue ? newValue.roleName : null);
                            handleRoleChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Role"
                              variant="outlined"
                              className="rounded-sm w-full"
                              size="small"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="parentId">
                  {({ input, meta }) => (
                    <div>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="parentId"
                          value={input.value !== undefined ? input.value : null}
                          options={menuData}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option?.displayName || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(
                              newValue ? newValue.displayName : null
                            );
                            handleMenuChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Parent Menu"
                              variant="outlined"
                              className="rounded-sm w-full"
                              size="small"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="displayName">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Menu Name "
                      variant="outlined"
                      className="rounded-sm w-full"
                      size="small"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="pageURL">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Page Url "
                      variant="outlined"
                      className="rounded-sm w-full"
                      size="small"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="parentOrder">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Parent Order "
                      variant="outlined"
                      className="rounded-sm w-full"
                      type="number"
                      size="small"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="childOrder">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Child Order "
                      variant="outlined"
                      className="rounded-sm w-full"
                      size="small"
                      type="number"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Field name="cssClass">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Svg Icon Path "
                      variant="outlined"
                      className="rounded-sm w-full"
                      multiline
                      rows={4}
                      size="small"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={submitting}
                  variant="contained"
                  color="primary"
                  className="w-26 h-10 bg-addButton text-white p-3 text-xs rounded-sm"
                >
                  {p ? "Edit Menu" : "Add Menu"}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
      <Toaster position="bottom-center" reverseOrder={false} />
    </>
  );
};
export default AddMenuFrom;
