import React, { useEffect, useState } from "react";
import MenuComponent from "../Menu/AddMenu/MenuComponent";
import WarperAddTable from "../Wrapper/WarperAddTable";
import toast, { Toaster } from "react-hot-toast";
import {
  Alert,
  Autocomplete,
  Button,
  Chip,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { Field, Form } from "react-final-form";
import useSubscription from "../../../Hooks/Subscription/useSubscription";
import {
  IEditSubscription,
  ISubscription,
} from "../../../api/Subscriptions/subscriptionType";
import { IMenu } from "../../../api/Menu/IMenuType";
import EditSubscriptionService from "../../../api/Subscriptions/EditSubscription/EditSubscriptionService";
import { header, SafeKaroUser } from "../../../context/constant";
import useGetRoles from "../../../Hooks/Role/useGetRoles";
const AssignMenu = () => {
  const [selectedItems, setSelectedItems] = useState<IMenu[]>([]);
  const [assignMenuIds, setAssignMenuIds] = useState<string[]>();
  const [subsData] = useSubscription();
  const [selectedSubs, setSelectedSubs] = useState<ISubscription>();
  const [roles] = useGetRoles();
  const [selectedRoleId, SetSelectedRoleId] = useState<string>("");
  let storedTheme: any = localStorage.getItem("user") as SafeKaroUser | null;
  let UserData = storedTheme ? JSON.parse(storedTheme) : storedTheme;
  const handleSubscriptionChange = (newValue: any) => {
    if (newValue?._id) {
      setSelectedSubs(newValue);
    }
  };
  const findRoleById = () => {
    return roles.find((ele) => ele._id === selectedRoleId);
  };
  const handleRoleChange = (role: any) => {
    if (role?._id) {
      SetSelectedRoleId(role._id);
    } else {
      SetSelectedRoleId("");
    }
  };
  const handleDelete = (item: IMenu) => {
    const gatherIdsToDelete = (menuItem: IMenu): string[] => {
      let ids = [menuItem._id];
      if (menuItem.children) {
        menuItem.children.forEach((child) => {
          ids = [...ids, ...gatherIdsToDelete(child)];
        });
      }
      return ids;
    };
    const idsToDelete = gatherIdsToDelete(item);
    const newSelectedItems = selectedItems.filter(
      (selectedItem) => !idsToDelete.includes(selectedItem._id)
    );
    setSelectedItems(newSelectedItems);
  };
  const callEditSubs = async (payload: IEditSubscription) => {
    try {
      const res = await EditSubscriptionService({
        header,
        editSubs: payload,
        id: selectedSubs?._id!,
      });
      if (res.status) {
        setSelectedItems([]);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  const handleRoleBasedMenuAssignment = (
    selectedSubs: ISubscription,
    roleName: string
  ) => {
    switch (roleName) {
      case "partner":
        setAssignMenuIds(selectedSubs.assignedPartnerMenu);
        break;
      case "booking":
        setAssignMenuIds(selectedSubs.assignedBookingMenu);
        break;
      case "relationship manager":
        setAssignMenuIds(selectedSubs.assignedRMMenu);
        break;
      case "operation":
        setAssignMenuIds(selectedSubs.assignedOperationMenu);
        break;
      case "account":
        setAssignMenuIds(selectedSubs.assignedAccountMenu);
        break;
      case "hr":
        setAssignMenuIds(selectedSubs.assignedHRMenu);
        break;
      default:
        setAssignMenuIds(selectedSubs.assignedAdminMenu);
    }
  };
  useEffect(() => {
    if (selectedSubs && selectedRoleId) {
      const roleData = findRoleById();
      const roleName = roleData?.roleName?.toLowerCase();
      if (roleName) {
        handleRoleBasedMenuAssignment(selectedSubs, roleName);
      }
    }
    // eslint-disable-next-line
  }, [selectedSubs, selectedRoleId]);
  const mapAssignByRole = () => {
    const roleData = findRoleById();
    const roleName = roleData?.roleName?.toLowerCase();
    switch (roleName) {
      case "partner":
        return "assignedPartnerMenu";
      case "booking":
        return "assignedBookingMenu";
      case "relationship manager":
        return "assignedRMMenu";
      case "operation":
        return "assignedOperationMenu";
      case "account":
        return "assignedAccountMenu";
      case "hr":
        return "assignedHRMenu";
      case "admin":
        return "assignedAdminMenu";
      default:
        return "assignedMenu";
    }
  };
  const onSubmit = (value: any) => {
    if (!selectedSubs?._id) {
      toast.error("Please select subscription plan");
      return;
    }
    if (selectedItems.length <= 0) {
      toast.error("Please select at least one menu");
      return;
    }
    const menuIds = selectedItems.map((ele) => ele._id);
    const payload: IEditSubscription = { updatedBy: UserData.name };
    const menuKey = mapAssignByRole();
    if (typeof menuKey === "string") {
      payload[menuKey] = menuIds;
    }
    callEditSubs(payload);
  };
  return (
    <>
      <WarperAddTable preLink="menu" title={"Assign Menu"}>
        <>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting, errors }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2} className="p-4">
                  <Grid item lg={4} md={6} sm={12} xs={12}>
                    <Field name="subscription">
                      {({ input, meta }) => (
                        <FormControl fullWidth size="small">
                          <Autocomplete
                            {...input}
                            id="subscription"
                            value={input.value || null}
                            options={subsData}
                            getOptionLabel={(option) =>
                              typeof option === "string"
                                ? option
                                : option?.planName || ""
                            }
                            onChange={(event, newValue) => {
                              input.onChange(
                                newValue ? newValue.planName : null
                              );
                              handleSubscriptionChange(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Subscription Plan"
                                variant="outlined"
                                size="small"
                                className="rounded-sm"
                                error={meta.touched && !!meta.error}
                                helperText={meta.touched && meta.error}
                              />
                            )}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Field name="role">
                      {({ input, meta }) => (
                        <div>
                          <FormControl fullWidth size="small">
                            <Autocomplete
                              {...input}
                              id="role"
                              value={
                                input.value !== undefined ? input.value : null
                              }
                              options={roles}
                              getOptionLabel={(option) =>
                                typeof option === "string"
                                  ? option
                                  : option?.roleName || ""
                              }
                              onChange={(event, newValue) => {
                                input.onChange(
                                  newValue ? newValue.roleName : null
                                );
                                handleRoleChange(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Role"
                                  variant="outlined"
                                  className="rounded-sm w-full"
                                  size="small"
                                  error={meta.touched && !!meta.error}
                                  helperText={meta.touched && meta.error}
                                />
                              )}
                            />
                          </FormControl>
                        </div>
                      )}
                    </Field>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <h1 className="text-base font-medium mb-1">
                      Selected Menu
                    </h1>
                    <div className="flex flex-wrap gap-2 p-3 bg-gray-50 rounded-md shadow-sm">
                      {selectedItems?.length > 0 ? (
                        selectedItems.map((ele) => (
                          <Chip
                            key={ele._id}
                            label={ele.displayName}
                            onDelete={() => handleDelete(ele)}
                          />
                        ))
                      ) : (
                        <span className="text-gray-500">No items selected</span>
                      )}
                    </div>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div
                      style={{
                        maxHeight: "300px",
                        overflowY: "auto",
                        padding: "1rem",
                        backgroundColor: "#f9f9f9",
                        border: "1px solid #e0e0e0",
                        borderRadius: "4px",
                      }}
                    >
                      {selectedRoleId && selectedSubs ? (
                        <MenuComponent
                          selectedItems={selectedItems}
                          setSelectedItems={setSelectedItems}
                          roleId={selectedRoleId || ""}
                          assignMenuIds={assignMenuIds || []}
                        />
                      ) : (
                        <Alert severity="error">
                          Please Select Role & Subscription
                        </Alert>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      disabled={submitting}
                      variant="contained"
                      color="primary"
                      className="w-26 h-10 bg-addButton text-white p-3 text-xs rounded-sm"
                    >
                      Assign Menu
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          />
          <Toaster position="bottom-center" reverseOrder={false} />
        </>
      </WarperAddTable>
    </>
  );
};
export default AssignMenu;
