import { Paper, Typography } from "@mui/material";
import React, { FC } from "react";
import { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
interface WarperAddTableProps {
  title: string;
  preLink: string;
  prePageName?: string;
  children: React.ReactNode;
}
const WarperAddTable: FC<WarperAddTableProps> = ({
  title,
  preLink,
  prePageName,
  children,
}) => {
  return (
    <div className="bg-blue-200 md:P-6 ">
      <Paper
        elevation={3}
        style={{ padding: 20, margin: 30, borderRadius: 10 }}
      >
        <Typography className="text-safekaroDarkOrange" variant="h5">
          {title}
        </Typography>
        <Typography variant="h5" mb={2}>
          <Link
            to="/super-admin/dashboard"
            className="text-addButton font-bold text-sm"
          >
            Dashboard {" / "}
          </Link>
          <Link to={`/${preLink}`} className="text-addButton font-bold text-sm">
            {prePageName?prePageName+"/":""} 
          </Link>
          <span className="text-grey-600 text-sm"> {title}</span>
          <hr
            className="mt-4"
            style={{ width: "100%", borderColor: "grey-800" }}
          />
        </Typography>
        {children}
      </Paper>
      <Toaster position="bottom-center" reverseOrder={false} />
    </div>
  );
};
export default WarperAddTable;
