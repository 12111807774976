import { Paper, Typography } from "@mui/material";
import React, { FC } from "react";
import { Link } from "react-router-dom";
interface WarperViewTableProps {
  title: string;
  children: React.ReactNode;
}
const WarperViewTable: FC<WarperViewTableProps> = ({ title, children }) => {
  return (
    <div className="bg-blue-200 md:p-7 p-2">
      <Paper elevation={3} style={{ padding: 30 }}>
        <Typography className="text-safekaroDarkOrange" variant="h5">
          {title} Table
        </Typography>
        <Typography variant="h5" mb={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <Link
                to="/super-admin/dashboard"
                className="text-addButton font-bold text-sm"
              >
                Dashboard /
              </Link>
              <span className="text-grey-600 text-sm"> {title}</span>
            </div>
            <Link
              to="add"
              className="w-26 h-10 bg-addButton text-white p-3 text-xs rounded-sm"
            >
              Add {title}
            </Link>
          </div>
          <hr
            className="mt-4"
            style={{ width: "100%", borderColor: "grey-800" }}
          />
        </Typography>
        {children}
      </Paper>
    </div>
  );
};
export default WarperViewTable;
