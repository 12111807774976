import React, { useEffect, useMemo, useState } from "react";
import { IAdmin } from "../IAdmin";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { header } from "../../../context/constant";
import getPartnersService from "../../../api/Partner/GetPartner/getPartnersService";
import toast, { Toaster } from "react-hot-toast";
import editTeamService from "../../../api/Team/EditTeam/editTeamService";
import { Link, useNavigate } from "react-router-dom";
import { IconButton, Paper, Tooltip, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
const Clients = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [adminData, setAdminData] = useState<IAdmin[]>();
  const columns = useMemo<MRT_ColumnDef<IAdmin>[]>(
    () => [
      {
        accessorKey: "fullName",
        header: "Full Name",
        size: 200,
      },
      {
        accessorKey: "role",
        header: "Role",
        size: 100,
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 200,
      },
      {
        accessorKey: "phoneNumber",
        header: "Phone Number",
        size: 150,
      },
      {
        accessorKey: "gender",
        header: "Gender",
        size: 100,
      },
      {
        accessorKey: "planName",
        header: "Plan",
        size: 100,
      },
      {
        header: "Status",
        accessorKey: "isActive",
        size: 50,
        Cell: ({ cell }) => {
          const value = cell.getValue<boolean>();
          return value ? (
            <CheckCircleOutlineIcon color="success" />
          ) : (
            <CancelOutlinedIcon color="error" />
          );
        },
      },
      {
        accessorKey: "wallet",
        header: "Wallet",
        size: 100,
        Cell: ({ cell }) => `₹${cell.getValue<number>()}`,
      },
      {
        accessorKey: "joiningDate",
        header: "Joining Date",
        size: 150,
        Cell: ({ cell }) =>
          new Date(cell.getValue<string>()).toLocaleDateString("en-IN"),
      },
      {
        accessorKey: "createdOn",
        header: "Created On",
        size: 150,
        Cell: ({ cell }) =>
          new Date(cell.getValue<string>()).toLocaleDateString("en-IN"),
      },
      {
        accessorKey: "updatedOn",
        header: "Updated On",
        size: 150,
        Cell: ({ cell }) => {
          const value = cell.getValue<string | null>();
          return value
            ? new Date(value).toLocaleDateString("en-IN")
            : "Not Updated";
        },
      },
    ],
    []
  );
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await getPartnersService({ header, role: "admin" });
      setAdminData(res.data);
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const changeStatus = async (row: IAdmin) => {
    try {
      const team = new FormData();
      team.append("isActive", String(!row.isActive));
      const res = await editTeamService({ team, teamId: row._id });
      if (!!res.data) {
        fetchData();
        navigate("/super-admin/client/new-rq");
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  return (
    <div className="bg-blue-200 md:p-7 p-2">
      <Paper elevation={3} style={{ padding: 30 }}>
        <Typography className="text-safekaroDarkOrange" variant="h5">
          Admin Table
        </Typography>
        <Typography variant="h5" mb={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <Link
                to="/super-admin/dashboard"
                className="text-addButton font-bold text-sm"
              >
                Dashboard /
              </Link>
              <span className="text-grey-600 text-sm"> Team</span>
            </div>
            <Link
              to="add"
              type="button"
              className="w-26 h-10 bg-addButton text-white p-3 text-xs rounded-sm"
            >
              Add Client
            </Link>
          </div>
          {}
          <hr
            className="mt-4"
            style={{ width: "100%", borderColor: "grey-800" }}
          />
        </Typography>
        <MaterialReactTable
          state={{ isLoading, pagination }}
          columns={columns}
          data={(adminData || []).filter((row) => row.isActive === true)}
          enableRowActions
          positionActionsColumn="last"
          onPaginationChange={setPagination}
          autoResetPageIndex={false}
          renderRowActions={({ row }) => (
            <div style={{ display: "flex", flexWrap: "nowrap" }}> 
              <Tooltip title={"Change Status"}>
                <IconButton
                  color="primary"
                  aria-label={"Change Status"}
                  component="span"
                  onClick={() => changeStatus(row.original)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-5 text-addButton"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                </IconButton>
              </Tooltip>
            </div>
          )}
        />
      </Paper>
      <Toaster position="bottom-center" reverseOrder={false} />
    </div>
  );
};
export default Clients;
