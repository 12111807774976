
import GetPaymentAPI from "./GetPaymentAPI";


const GetPaymentService = async (  ): Promise<any> => {

 try {
    const res = await GetPaymentAPI()
      return res;
 } catch (error) {
    throw error;
 }

};

export default GetPaymentService;