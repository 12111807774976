import { FC, useState } from "react";
import {
  IAddSubscription,
  IEditSubscription,
  ISubscription,
} from "../../../../api/Subscriptions/subscriptionType";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import toast, { Toaster } from "react-hot-toast";
import { Field, Form } from "react-final-form";
import * as yup from "yup";
import { Button, Grid, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditSubscriptionService from "../../../../api/Subscriptions/EditSubscription/EditSubscriptionService";
import AddSubscriptionService from "../../../../api/Subscriptions/AddSubscription/AddSubscriptionService";
import { SafeKaroUser } from "../../../../context/constant";
import { setIn } from "final-form";
import { header } from "../../../../context/constant";
interface AddSubsFromProps {
  p?: ISubscription;
}
const AddSubsFrom: FC<AddSubsFromProps> = ({ p }) => {
  const [editorValue, setEditorValue] = useState(p?.planDetails || "");
  const navigate = useNavigate();
  let storedTheme: any = localStorage.getItem("user") as SafeKaroUser | null;
  let UserData = storedTheme ? JSON.parse(storedTheme) : storedTheme;
  const callEditSubs = async (payload: IEditSubscription) => {
    try {
      const res = await EditSubscriptionService({
        header,
        editSubs: payload,
        id: p?._id!,
      });
      if (res.status) {
        navigate(-1);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  const callAddSubs = async (payload: IAddSubscription) => {
    try {
      const res = await AddSubscriptionService(payload);
      if (res.status) {
        navigate(-1);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  const validateFormValues = (schema: any) => async (values: any) => {
    if (typeof schema === "function") {
      schema = schema();
    }
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (err: any) {
      const errors = err.inner.reduce((formError: any, innerError: any) => {
        return setIn(formError, innerError.path, innerError.message);
      }, {});
      return errors;
    }
  };
  const validationSchema = yup.object().shape({
    planName: yup
      .string()
      .required("Plan Name Name is required")
      .min(1, "Plan Name must be at least 1 character")
      .max(100, "Plan Name cannot exceed 100 characters"),
    users: yup
      .string()
      .required("User Name Name is required")
      .min(1, "User Name must be at least 1 character")
      .max(100, "User Name cannot exceed 100 characters"),
    monthlyAmount: yup.number().required("Monthly Amount is required"),
    annualAmount: yup.number().required("Yearly Amount is required"),
    policyCount: yup.number().required("Policy Count Amount is required"),
  });
  const validate = validateFormValues(validationSchema);
  const onSubmit = (values: any) => {
    values["planDetails"] = editorValue;

    if (p) {
      values["updatedBy"] = UserData.name;
      values["header"] = header;
      callEditSubs(values);
    } else {
      values["createdBy"] = UserData.name;
      callAddSubs(values);
    }
  };
  const generateInitialValue = () => {
    return {
      planName: p?.planName,
      users: p?.users,
      monthlyAmount: p?.monthlyAmount,
      annualAmount: p?.annualAmount,
      policyCount: p?.policyCount,
    };
  };
  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={generateInitialValue()}
        validate={validate}
        render={({ handleSubmit, submitting, errors }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="planName">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Plan Name "
                      variant="outlined"
                      className="rounded-sm w-full"
                      size="small"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="users">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter user "
                      variant="outlined"
                      className="rounded-sm w-full"
                      type="text"
                      size="small"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="monthlyAmount">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Monthly Amount "
                      variant="outlined"
                      className="rounded-sm w-full"
                      type="number"
                      size="small"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="annualAmount">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Annual Amount "
                      variant="outlined"
                      className="rounded-sm w-full"
                      type="number"
                      size="small"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Field name="policyCount">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Policy Limit "
                      variant="outlined"
                      className="rounded-sm w-full"
                      type="number"
                      size="small"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <label className="font-semibold ">Description</label>
                <ReactQuill
                  theme="snow"
                  value={editorValue}
                  onChange={setEditorValue}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={submitting}
                  variant="contained"
                  color="primary"
                  className="w-26 h-10 bg-addButton text-white p-3 text-xs rounded-sm"
                >
                  {p ? "Update Subscription" : "Add Subscription"}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
      <Toaster position="bottom-center" reverseOrder={false} />
    </>
  );
};
export default AddSubsFrom;
