import AddRole from "./components/Admin/Role/AddRole/addRole";
import Signin from "./Auth/Signin";
import SuperAdminDashBoard from "./components/Dashboard/SuperAdminDashBoard";
import NewReq from "./components/Client/NewRequest/NewReq";
import Roles from "./components/Admin/Role/Roles/Roles";
import Clients from "./components/Client/ActiveClients/Clients";
import AddClient from "./components/Client/ActiveClients/AddClient/AddClient";
import SubsCription from "./components/Admin/Subscriptions/SubsCription";
import AddSubs from "./components/Admin/Subscriptions/AddSubscription/AddSubs";
import Menu from "./components/Admin/Menu/Menu";
import AddMenu from "./components/Admin/Menu/AddMenu/AddMenu";
import AssignMenu from "./components/Admin/AssignMenu/AssignMenu";
import Payment from "./components/Payments/Payment";
const routes = [
  {
    path: "/",
    children: [
      {
        path: "",
        element: <Signin />,
      },
    ],
  },
  {
    path: "/super-admin",
    children: [
      {
        path: "dashboard",
        element: <SuperAdminDashBoard />,
      },
      {
        path: "client/new-rq",
        element: <NewReq />,
      },
      {
        path: "clients",
        children: [
          {
            path: "",
            element: <Clients />,
          },
          {
            path: "add",
            element: <AddClient />,
          },
          {
            path: "edit",
            element: <AddClient />,
          },
        ],
      },
    ],
  },
  {
    path: "role",
    children: [
      {
        path: "",
        element: <Roles />,
      },
      {
        path: "add",
        element: <AddRole />,
      },
      {
        path: "/role/:roleId/edit",
        element: <AddRole />,
      },
    ],
  },
  {
    path: "subscription",
    children: [
      {
        path: "",
        element: <SubsCription />,
      },
      {
        path: "add",
        element: <AddSubs />,
      },
      {
        path: "edit",
        element: <AddSubs />,
      },
    ],
  },
  {
    path: "menu",
    children: [
      {
        path: "",
        element: <Menu />,
      },
      {
        path: "add",
        element: <AddMenu />,
      },
      {
        path: "edit",
        element: <AddMenu />,
      },
    ],
  },
  {
    path: "assign-menu",
    children: [
      {
        path: "",
        element: <AssignMenu />,
      },
    ],
  },
  {
    path: "payments",
    children: [
      {
        path: "",
        element: <Payment/>,
      },
    ],
  },
];
export default routes;
