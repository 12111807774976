import  { useEffect, useMemo, useState } from "react";
import {
  EditProps,
  ISubscription,
} from "../../../api/Subscriptions/subscriptionType";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import dayjs from "dayjs";
import {
  DAYJS_DISPLAY_FORMAT,
  SafeKaroUser,
} from "../../../context/constant";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { IconButton, Tooltip } from "@mui/material";
import EditSubscriptionService from "../../../api/Subscriptions/EditSubscription/EditSubscriptionService";
import { header } from "../../../context/constant";
import GetSubscriptionService from "../../../api/Subscriptions/GetSubscription/GetSubscriptionService";
import WarperViewTable from "../Wrapper/WarperViewTable";
const SubsCription = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [subsData, setSubsData] = useState<ISubscription[]>();
  let storedTheme: any = localStorage.getItem("user") as SafeKaroUser | null;
  let UserData = storedTheme ? JSON.parse(storedTheme) : storedTheme;
  const columns = useMemo<MRT_ColumnDef<ISubscription>[]>(
    () => [
      {
        accessorKey: "planName",
        header: "Plan Name",
        size: 200,
      },
      {
        accessorKey: "monthlyAmount",
        header: "Monthly Amount",
        size: 150,
      },
      {
        accessorKey: "annualAmount",
        header: "Annual Amount",
        size: 150,
      },
      {
        accessorKey: "policyCount",
        header: "Policy Count",
        size: 100,
      },
      {
        accessorKey: "createdOn",
        header: "Created On",
        size: 150,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return dayjs(value as string | number | Date).format(
            DAYJS_DISPLAY_FORMAT
          );
        },
      },
      {
        accessorKey: "createdBy",
        header: "Created By",
        size: 150,
      },
      {
        header: "Status",
        accessorKey: "isActive",
        size: 50,
        Cell: ({ cell }) => {
          const value = cell.getValue<boolean>();
          return value ? (
            <CheckCircleOutlineIcon color="success" />
          ) : (
            <CancelOutlinedIcon color="error" />
          );
        },
      },
    ],
    []
  );
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await GetSubscriptionService({ header });
      if (res.status === "success") {
        setSubsData(res.data);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickEditSubscription = (row: ISubscription) => {
    navigate("edit", { state: row });
  };
  const handleClickChangeStatus = async (row: ISubscription) => {
    try {
      const editData: EditProps = {
        id: row._id,
        header,
        editSubs: { isActive: !row.isActive, updatedBy: UserData.name },
      };
      const res = await EditSubscriptionService(editData);
      if (res.status === "success") {
        fetchData();
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <WarperViewTable title="Subscription">
        <MaterialReactTable
          state={{ isLoading, pagination }}
          columns={columns}
          data={subsData || []}
          enableRowActions
          onPaginationChange={setPagination}
          autoResetPageIndex={false}
          paginateExpandedRows={false}
          positionActionsColumn="last"
          renderRowActions={({ row }) => (
            <div style={{ display: "flex", flexWrap: "nowrap" }}>
              <Tooltip title={"Edit Subscription"}>
                <IconButton
                  color="primary"
                  aria-label={"Edit Subscription"}
                  component="span"
                  onClick={() => {
                    handleClickEditSubscription(row.original as ISubscription);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-5 text-addButton"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                    />
                  </svg>
                </IconButton>
              </Tooltip>
              <Tooltip title={"Change Status"}>
                <IconButton
                  color="primary"
                  aria-label={"Change Status"}
                  component="span"
                  onClick={() =>
                    handleClickChangeStatus(row.original as ISubscription)
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-5 text-addButton"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                </IconButton>
              </Tooltip>
              <Link to="/assign-menu">
                <Tooltip title={"View Subscription"}>
                  <IconButton
                    color="primary"
                    aria-label={"View Subscription"}
                    component="span"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6 text-addButton"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                  </IconButton>
                </Tooltip>
              </Link>
            </div>
          )}
        />
      </WarperViewTable>
      <Toaster position="bottom-center" reverseOrder={false} />
    </>
  );
};
export default SubsCription;
