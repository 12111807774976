import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { signupEp as endpoint } from "../apiEndPoints";
import { AddSignUp } from "../IAuth";

const SignupApi = async ({  signupData }: AddSignUp) => {
  const url = endpoint()
  const options: FetchOptions = {
    method: "POST",
    body:signupData
  }
  return fetchInterceptor(url, options)
};
export default SignupApi;
