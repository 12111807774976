import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { getAllPaymentEp as endpoint } from "../apiEndpoints";
import { header } from "../../../context/constant";
const GetPaymentAPI = async () => {
  const url = endpoint()
  const options: FetchOptions = {
    method: "GET",
    headers: header,
  }
  return fetchInterceptor(url, options)
};

export default GetPaymentAPI;