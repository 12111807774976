import { Typography, Paper } from "@mui/material";
import { Link } from "react-router-dom";

import AddClientFrom from "./AddClientFrom";

const AddClient = () => {
  const title = "Add Client";
  return (
    <>
      <div className="bg-blue-200 md:p-7">
        <Paper
          elevation={3}
          style={{ padding: 20, margin: 30, borderRadius: 10 }}
        >
          <Typography className="text-safekaroDarkOrange" variant="h5">
            {title}
          </Typography>
          <Typography variant="h5" mb={2}>
            <Link
              to="/super-admin/dashboard"
              className="text-addButton font-bold text-sm"
            >
              Dashboard /
            </Link>
            <Link to="/super-admin/clients" className="text-addButton font-bold text-sm">
              Client /
            </Link>
            <span className="text-grey-600 text-sm"> {title}</span>
            <hr
              className="mt-4"
              style={{ width: "100%", borderColor: "grey-800" }}
            />
          </Typography>
          <AddClientFrom/>
      
        </Paper>
      </div>
    </>
  );
};
export default AddClient;
