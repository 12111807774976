import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { editSubscriptionEndpoint as endpoint } from "../apiEndPoints";
import { EditProps } from "../subscriptionType";
const EditSubscriptionApi = async ({ header, editSubs, id }: EditProps) => {
    const url = endpoint(id)
    const options: FetchOptions = {
        method: "PUT",
        headers: header,
        body: JSON.stringify({
            ...editSubs
        }),
    }
    return fetchInterceptor(url, options)
};
export default EditSubscriptionApi;