import { header } from "../../../context/constant";
import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { addMenuEndpoint as endpoint } from "../apiEndpoints";
import { IAddMenu } from "../IMenuType";
const AddMenuAPI = async (payload: IAddMenu) => {
  const url = endpoint()
  const options: FetchOptions= {
    method: "POST",
    headers:header,
    body: JSON.stringify({
      ...payload,
    }),
  }
  return fetchInterceptor(url, options)
};
export default AddMenuAPI;
