import React, { useCallback, useState } from "react";
import { SafeKaroUser } from "../../context/constant";
import { Link } from "react-router-dom";
import { Avatar, Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
interface HeaderProps {
  isSidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const Header = React.memo<HeaderProps>(({ isSidebarOpen, setSidebarOpen }) => {
  const storedTheme: any = localStorage.getItem("user") as SafeKaroUser | null;
  const UserData = storedTheme ? JSON.parse(storedTheme) : storedTheme;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const signOut = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    handleClose();
  }, []);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };
  return (
    <>
      <div className="flex items-center justify-between bg-white py-2 px-2">
        <div className="mx-2 md:hidden">
          {isSidebarOpen ? (
            <div onClick={handleSidebar}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </div>
          ) : (
            <div onClick={() => setSidebarOpen(!isSidebarOpen)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                />
              </svg>
            </div>
          )}
        </div>
        <div className="md:flex hidden md:text-xl font-medium font-satoshi content-start">
          Welcome Back, {UserData?.name}
        </div>
        <div className="flex md:hidden text-sm font-medium font-satoshi content-start">
          {UserData?.name}
        </div>
        <div className="flex items-center justify-center gap-[2px]">
          <Avatar
            className="md:w-[50px] md:h-[50px] w-[30px] h-[30px]"
            alt={UserData?.name}
            src=""
          />
          <div className="flex items-center justify-between space-x-0 bg-white px-4">
            <Link to="/profile" className="menu-hover text-black lg:mx-4">
              <p className="md:text-[16px] text-[12px] font-medium font-satoshi">
                {UserData?.name} {" ("} {UserData?.partnerCode} {")"}
              </p>
              <p className="text-[#737791] md:text-sm text-[10px]">
                {UserData?.role}
              </p>
            </Link>
          </div>
          <div className="self-start">
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              sx={{ color: "black" }}
              onClick={handleClick}
            >
              <KeyboardArrowDownIcon />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Link
                to="/profile"
                className="my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-1"
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
              </Link>
              <Link to="/" onClick={signOut}>
                <MenuItem>Logout</MenuItem>
              </Link>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
});
export default Header;
