import { IEditMenu } from "../IMenuType";
import EditMenuApi from "./EditMenuApi";
const EditMenuService = async (payload: IEditMenu): Promise<any> => {
    try {
        const res = await EditMenuApi(payload)
        return res;
    } catch (error) {
        throw error
    }
};
export default EditMenuService;
