import React, { useState, useEffect } from "react";
import { header } from "../../../../context/constant";
import GetMenuService from "../../../../api/Menu/GetMenu/GetMenuService";
import { IMenu } from "../../../../api/Menu/IMenuType";
import {
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Alert,
} from "@mui/material";
interface MenuComponentProps {
  selectedItems: IMenu[];
  setSelectedItems: React.Dispatch<React.SetStateAction<IMenu[]>>;
  roleId: string;
  assignMenuIds: string[];
}
const MenuComponent: React.FC<MenuComponentProps> = ({
  selectedItems,
  setSelectedItems,
  roleId,
  assignMenuIds,
}) => {
  const [menuData, setMenuData] = useState<IMenu[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState("");
  const fetchData = async () => {
    try {
      setLoading(true);
      setError("");
      const res = await GetMenuService({ header, roleId });
      if (res.status === "success") {
        const hierarchicalMenu = buildMenuHierarchy(res.data);
        setMenuData(hierarchicalMenu);
      }
    } catch (error: any) {
      const err = await error;
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (roleId) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [roleId]);
  const buildMenuHierarchy = (
    menuItems: IMenu[],
    parentId: string | null = "0"
  ): IMenu[] => {
    return menuItems
      .filter((item) => item.parentId === parentId)
      .sort((a, b) => {
        if (a.parentOrder !== b.parentOrder) {
          return (a.parentOrder ?? 0) - (b.parentOrder ?? 0);
        }
        return (a.childOrder ?? 0) - (b.childOrder ?? 0);
      })
      .map((item) => ({
        ...item,
        children: buildMenuHierarchy(menuItems, item._id),
      }));
  };
  const handleCheckboxChange = (item: IMenu) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(item)) {
        return prevSelectedItems.filter((pre) => pre._id !== item._id);
      } else {
        return [...prevSelectedItems, item];
      }
    });
  };
  useEffect(() => {
    if (menuData.length > 0) {
      const findAssignedMenus = (menus: IMenu[]): IMenu[] => {
        return menus.reduce((acc: IMenu[], menu) => {
          if (assignMenuIds.includes(menu._id)) {
            acc.push(menu);
          }
          if (menu.children && menu.children.length > 0) {
            acc.push(...findAssignedMenus(menu.children));
          }
          return acc;
        }, []);
      };
      const alreadyAssignMenu = findAssignedMenus(menuData);
      setSelectedItems([...alreadyAssignMenu]);
    }
    // eslint-disable-next-line
  }, [assignMenuIds.length, menuData.length]);
  const renderMenu = (items: IMenu[], level: number = 0): JSX.Element => {
    return (
      <ul className="ml-5 list-none">
        {items.map((item) => (
          <li key={item._id} className="mb-2.5">
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedItems.includes(item)}
                  onChange={() => handleCheckboxChange(item)}
                />
              }
              label={
                <span
                  style={{
                    textDecoration: "none",
                    fontWeight: level === 0 ? "bold" : "normal",
                    color: level === 0 ? "#333" : "#555",
                  }}
                >
                  {item.displayName}
                </span>
              }
            />
            {item.children &&
              item.children.length > 0 &&
              renderMenu(item.children, level + 1)}
          </li>
        ))}
      </ul>
    );
  };
  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }
  return (
    <div className="p-5 hide-scrollbar overflow-y-auto max-h-60 w-full">
      <span className="text-base font-medium">Select Menu</span>
      {loading ? <CircularProgress /> : renderMenu(menuData)}
    </div>
  );
};
export default MenuComponent;
