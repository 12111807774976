import { AddSignUp } from "../IAuth";
import SignupApi from "./SignupApi";
const SignUpService = async ({  signupData }: AddSignUp): Promise<any> => {
    try {
        const resData = SignupApi({
            signupData
        })
        return resData
    } catch (error) {
        throw error;
    }
};
export default SignUpService;
